<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <DxLoadPanel :position="{ of: '#form' }" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />

    <AlertBox :result="result" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <DxButton text="Регистрация компании" @click="registerCompanyClick" class="mb-2" />

            <DxDataGrid id="gvCompanies" ref="gvCompanies" v-bind="options" :data-source="companiesData">
              <DxColumn data-field="name" caption="Название" />
              <DxColumn data-field="inn" caption="ИНН" />
              <DxColumn data-field="userName" caption="Админ - логин" />
              <DxColumn data-field="userShortName" caption="Админ - фио" />

              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="10" />
              <DxPager :visible="true" :allowed-page-sizes="[5, 10, 50]" :show-page-size-selector="true"
                :show-navigation-buttons="true" :show-info="true" />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>

    <DxPopup ref="dxPopup" :visible="false" :drag-enabled="false" :close-on-outside-click="true"
      :show-close-button="false" :show-title="true" title="Создание компании">
      <DxPosition at="center" my="center" />
      <form @submit="onFormSubmit($event)" id="form">
        <DxValidationSummary class="pb-2" />

        <AlertBox :result="result" />

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label>Название</label>
              <DxTextBox :show-clear-button="true" v-model="company.name">
                <DxValidator>
                  <DxRequiredRule message="Поле 'Название' обязательное" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>ИНН</label>
              <DxTextBox :show-clear-button="true" v-model="company.inn">
                <DxValidator>
                  <DxRequiredRule message="Поле 'ИНН' обязательное" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>Логин</label>
              <DxTextBox :show-clear-button="true" v-model="company.userName">
                <DxValidator>
                  <DxRequiredRule message="Поле 'Логин' обязательное" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>Пароль</label>
              <DxTextBox :show-clear-button="true" mode="password" v-model="company.password"
                :input-attr="{ autocomplete: 'new-password' }">
                <DxValidator>
                  <DxRequiredRule message="Поле 'Пароль' обязательное" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>ФИО сокращенно</label>
              <DxTextBox :show-clear-button="true" v-model="company.userShortName">
                <DxValidator>
                  <DxRequiredRule message="Поле 'ФИО' обязательное" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>Телефон</label>
              <DxTextBox :show-clear-button="true" v-model="company.userPhone" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label>Email</label>
              <DxTextBox :show-clear-button="true" v-model="company.email">
                <DxValidator>
                  <DxEmailRule message="Email задан неверно" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
        </div>
        <div class="row my-4 fixed-bottom">
          <div class="col-md-6"></div>
          <div class="col-md-6 text-end">
            <div class="mx-2">
              <DxButton text="Сохранить" type="success" class="me-2" :use-submit-behavior="true" />
              <DxButton text="Отменить" @click="registerCompanyCancel" />
            </div>
          </div>
        </div>
      </form>
    </DxPopup>
    <!--  -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { DxPopup, DxPosition } from "devextreme-vue/popup";

import axios from "axios";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { DxEmailRule } from "devextreme-vue/validator";

const companiesData = createStoreExtend({
  key: "companyId",
  loadUrl: `${process.env.VUE_APP_URL}/api/companies/`,
});

export default {
  components: {
    Layout,
    PageHeader,
    DxPopup,
    DxPosition,
    DxValidationSummary,
    DxEmailRule,
  },

  data() {
    return {
      title: "Компании",
      items: [
        {
          text: "Справочник",
          href: "/",
        },
        {
          text: "Компании",
          active: true,
        },
      ],
      result: { successed: false, message: "", errors: [], show: false },
      options: settings,
      companiesData,
      company: Object.assign({}, this.emptyCompany()),
      isLoading: false,
    };
  },

  computed: {
    gridInstance: {
      get() {
        return this.$refs.gvCompanies.instance;
      },
    },
    popupInstance: {
      get() {
        return this.$refs.dxPopup.instance;
      },
    },
  },
  methods: {
    emptyCompany() {
      return {
        name: "",
        inn: "",
        userShortName: "",
        userPhone: "",
        userEmail: "",
        userName: "",
        password: "",
      };
    },
    resetCompany() {
      this.company = Object.assign({}, this.emptyCompany());
    },
    registerCompanyClick() {
      this.resetCompany();
      this.popupInstance.show();
    },
    registerCompanyCancel() {
      this.popupInstance.hide();
    },
    setResult(message, errors, isError) {
      this.result.message = message;
      this.result.errors = errors;
      this.result.show = true;
      this.result.successed = !isError;
      console.log(this.result);
    },
    onFormSubmit(e) {
      this.isLoading = true;
      this.loadingPanelPosition = { of: "#importTypes" };

      e.preventDefault();

      axios
        .post(`${process.env.VUE_APP_URL}/api/companies/`, this.company)
        .then((response) => {
          console.log(response);
          this.resetCompany();
          this.gridInstance.refresh();
          this.setResult("Компания успешно создана", [], false);
        })
        .catch((error) => {
          console.log(error.response);
          this.setResult(
            error.response.data.title,
            error.response.data.errors,
            true
          );
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style>
</style>
